import { PropsWithChildren } from 'react'
import { Footer } from '@/components/universal/Footer'
import { Header } from '@/components/universal/Header'

export default function MainLayout({ children }: PropsWithChildren) {
  return (
    <>
      <Header />
      <main className="bg-slate-100">{children}</main>
      <Footer />
    </>
  )
}