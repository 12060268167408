
import Head from 'next/head'
import MainLayout from '@/components/layouts/main'
import DefaultHomepage from './landingpages/defaultpage.jsx'

export default function IndexPage() {
  return (
    <>
      <Head>
        <title>Credify - Get Turbo Approved Now</title>
        <meta
          name="description"
          content="Survey potentially hundreds of lenders on autopilot and enjoy our Best Rate Guarantee!"
        />
      </Head>
      <MainLayout>
        <DefaultHomepage />
      </MainLayout>
    </>
  )
}
