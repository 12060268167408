import Link from 'next/link'
import { useState, useEffect, useRef } from 'react'
import { LinkButton } from '@/components/uikit/Button'
import { Container } from '@/components/uikit/Container'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { gistOpenChat } from '@/lib/gtm'
import useAppStore from '@/store'
import clsx from 'clsx'

export default function DefaultHomepage() {
  const [flowVersion, setFlowVersion] = useState('1')
  const flowRotated = useRef(false)

  const rotateFlowVersion = () => {
    let minVersion = 1
    let maxVersion = 2
    let randomVersion = Math.round(
      Math.random() * (maxVersion - minVersion) + minVersion
    )
    setFlowVersion((oldValue) => randomVersion)
  }

  useEffect(() => {
    if (flowRotated.current) return
    flowRotated.current = true
    rotateFlowVersion()
  }, [])

  const partner = useAppStore((state) => state.partner)
  const partner_loaded = useAppStore((state) => state.partner_loaded)

  const flows = [
    {
      name: 'Purchase | FHA, VA, USDA',
      url: `/flows/purchase/v${flowVersion}/step1`,
      buttonClass: 'col-span-2'
    },
    {
      name: 'Purchase | Conventional, Jumbo',
      url: `/flows/purchase/v${flowVersion}/step1`,
      buttonClass: 'col-span-2'
    },    
    {
      name: 'Refinance',
      url: `/flows/refinance/v2/step1`,
      buttonClass: 'col-span-2'
    },
    {
      name: 'HELOC',
      url: `/flows/heloc/v2/step1`,
      buttonClass: 'col-span-2'
    },
    {
      name: 'Home Equity',
      url: `/flows/home-equity/v2/step1`,
      buttonClass: 'col-span-2'
    },
    {
      name: 'Investment Properties',
      url: `/flows/purchase/v${flowVersion}/step1`,
      buttonClass: 'col-span-2'
    }    
  ]

  return (
    <Container>
      <div className="col-span-1 mx-auto mb-10 grid max-w-2xl grid-flow-row gap-4">
        <h1 className="text-center text-xl md:text-2xl">
          Survey potentially <strong>hundreds</strong> of lenders on autopilot
          and enjoy our{' '}
          <span className="underline underline-offset-2">
            best rate guarantee!
          </span>
        </h1>
        <p className="text-center text-sm sm:text-base py-2">
          Click your application type below. You&apos;ll need about 5 minutes to
          complete it.
        </p>
        {partner_loaded && (
          <>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-4">
              {flows.map((flow) => (
                <div
                  key={flow.name}
                  className={clsx("relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-blue-400", flow.buttonClass)}
                >
                  <div className="min-w-0 flex-1">
                    <LinkButton
                      href={flow.url}
                      className="focus:outline-none w-full hover:bg-white active:bg-white"
                      color="white"
                      style={{
                        backgroundColor: partner.primary_hex_color || undefined,
                      }}
                    >
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm text-center font-medium text-gray-900">{flow.name}</p>
                    </LinkButton>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        <p className="mb-4 mt-4 text-center text-sm">
          Need help? Chat with us{' '}
          <strong
            className="hover:cursor-pointer hover:text-blue-700"
            onClick={gistOpenChat}
          >
            HERE
          </strong>
        </p>
        <div className="rounded-md bg-blue-100 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                <strong>Best rate guarantee:</strong> Credify guarantees you
                will get the best rate currently available, and if not will pay
                double the difference in your monthly payments for 4 years.
              </p>
              <p className="mt-3 text-sm md:ml-6 md:mt-0">
                <a
                  href="https://www.getcredify.com/rate-guarantee"
                  className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                  rel="noreferrer"
                  target="_blank"
                >
                  See terms
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
