import Link from 'next/link'

import { LinkButton } from '@/components/uikit/Button'
import { Container } from '@/components/uikit/Container'
import { Logo } from '@/components/universal/Logo'

export function Header() {
  return (
    <header className="bg-white">
      <Container>
        <nav className="items-centers grid justify-center gap-4 sm:grid-cols-2">
          <div className="">
            <Link
              target="_blank"
              href="https://getcredify.com"
              aria-label="Home"
            >
              <Logo className="mx-auto h-10 w-auto sm:mx-0" />
            </Link>
          </div>
          <div className="">
            <LinkButton
              href="tel:+18005576196"
              color="slateLight"
              className="sm:float-right"
              target="_blank"
            >
              <span>Call us at +1 (800) 557-6196</span>
            </LinkButton>
          </div>
        </nav>
      </Container>
    </header>
  )
}
