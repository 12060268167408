import Image from 'next/image'
import LogoBlackSrc from '@/images/logos/full-black.svg'
import LogoWhiteSrc from '@/images/logos/full-white.svg'
import useAppStore from '@/store'

export function Logo(props) {
  const partner_loaded = useAppStore((state) => state.partner_loaded)
  const partner = useAppStore((state) => state.partner)

  if (!partner_loaded) {
    return null
  }

  return (
    <Image
      src={
        partner.logo ? `data:image/png;base64,${partner.logo}` : LogoBlackSrc
      }
      alt="Credify Logo"
      width={100}
      height={100}
      {...props}
    ></Image>
  )
}

export function LogoWhite(props) {
  return (
    <Image
      src={LogoWhiteSrc}
      alt="Credify Logo"
      width={100}
      height={100}
      {...props}
    ></Image>
  )
}
