import clsx from 'clsx'
import { PropsWithChildren } from 'react'

export function Container({
  className,
  ...props
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={clsx(
        'mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8',
        className
      )}
      {...props}
    />
  )
}
