import Link from 'next/link'

import { Container } from '@/components/uikit/Container'
import { LogoWhite } from '@/components/universal/Logo'
import { NavLink } from '@/components/uikit/NavLink'

export function Footer() {
  return (
    <footer className="bg-slate-800">
      <Container>
        <div className="py-12">
          <LogoWhite className="mx-auto h-10 w-auto" />
        </div>
        <div className="grid-gap-10 grid grid-flow-row grid-cols-1 border-t border-slate-100/20 pt-10 text-center">
          <p className="mt-0 text-sm text-slate-300">
            Copyright &copy; {new Date().getFullYear()} Credify Corporation. 235
            Grand Street, #3603, Jersey City, NJ 07302
          </p>
          <p className="mt-4 text-sm text-slate-300">
            NMLS ID # 2246018. Equal Housing Lender. All loans are subject to
            approval, to include the underwriting of credit and collateral.
          </p>
        </div>
        <nav className="py-10 text-sm" aria-label="quick links">
          <div className="grid justify-center gap-x-6 text-center md:grid-flow-col ">
            <NavLink
              target="_blank"
              href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/2246018"
            >
              NMLS Consumer Access
            </NavLink>
            <NavLink
              target="_blank"
              href="https://getcredify.com/disclosures-licensing"
            >
              Disclosures and Licensing
            </NavLink>
            <NavLink
              target="_blank"
              href="https://getcredify.com/privacy-policy"
            >
              Privacy Policy
            </NavLink>
            <NavLink target="_blank" href="https://getcredify.com/terms-of-use">
              Website Terms of Use
            </NavLink>
            <NavLink
              target="_blank"
              href="https://getcredify.com/rate-guarantee"
            >
              Rate Guarantee
            </NavLink>
          </div>
        </nav>
      </Container>
    </footer>
  )
}
